/**
 * Summary. Configuration parameters
 *
 * Description. Export configuration from centralize location
 *
 * @link   URL
 * @file   This files exports the config parameters, to minimize changes require at multiple places
 * @author Riyaz Ahmed.
 * @since  1.0.0
 */

const TERMS_CONDITION_URL = 'https://repsource.com/terms-of-use/';
const PRIVACY_POLICY_URL = 'https://repsource.com/privacy-policy/';
const ABOUT_US_URL = 'https://repsource.com/about-us/';
const CONTACT_US_URL = 'https://repsource.com/contact-us/';
// don't change this!!!
const AES_ENCRYPTION_KEY = '5v8y/A?D(G+KbPeShVmYq3t6w9z$C&E)';

module.exports = {
  TERMS_CONDITION_URL,
  PRIVACY_POLICY_URL,
  ABOUT_US_URL,
  CONTACT_US_URL,
  AES_ENCRYPTION_KEY,
};
