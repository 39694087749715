/**
 *
 * Description. Private Route
 *
 * @link   URL
 * @file   In any page where login is required then this function is used.
           It check whether user is logged in or not and then only user can see
           pages
 * @since  1.0.0
 */
import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import app from '../helpers/appGlobal';

// Rep's Pages
const repRoutes = [
  '/RepDashboard',
  '/RepMyAccount',
  '/RepMyColleagues',
  '/RepMyCredentials',
  '/RepMyProducts',
  '/Dashboard',
  '/FullProfile',
  '/ProviderRequests',
];
// Provider's pages
const providerRoutes = [
  '/SearchRep',
  '/Dashboard',
  '/OnCallReps',
  '/ScheduleRep',
  '/ScheduledAppointments',
];

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) => {
      if (app.user.id) {
        const { pathname } = props.location;
        if (
          pathname.indexOf('FullProfile') !== -1 ||
          pathname.indexOf('/r/') !== -1
        ) {
          const { profileId = 0, profileurl = '' } = props.match.params;
          return (
            <Component
              {...props}
              fullProfile="yes"
              profileId={profileId}
              profileurl={profileurl}
            />
          );
        }
        if (app.user.userType === 'rep') {
          if (repRoutes.indexOf(pathname) !== -1) {
            return <Component {...props} />;
          }
          return (
            <Redirect
              to={{
                pathname: '/RepDashboard',
              }}
            />
          );
        }
        if (providerRoutes.indexOf(pathname) !== -1) {
          return <Component {...props} />;
        }
        return (
          <Redirect
            to={{
              pathname: '/SearchRep',
            }}
          />
        );
      }
      return (
        <Redirect
          to={{
            pathname: '/Login',
            state: { from: props.location },
          }}
        />
      );
    }}
  />
);

export default PrivateRoute;
