// Reducer setup file
import * as actionType from '../actions/ActionType';

const initialState = {
  data: {},
};

const dashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionType.GET_USER_DETAILS:
      return {
        ...state,
        data: {
          ...{ ...action.payload },
        },
      };
    default:
      return state;
  }
};

export default dashboardReducer;
