/**
 * @doc
 * Global object for sharing functional helpers
 * This can be used in any file. Like user's login info will be stored in This
 * global
 */
const app = {
  locale: 'en',
  config: {
    API_URL: process.env.REACT_APP_API_URL,
    STRIPE_PUB_KEY: process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY,
  },
  user: {
    id: '',
    email: '',
    products: [],
  },
};

export default app;
