/**
 *
 * Description. Main Route File
 *
 * @link   URL
 * @file   Whenever user hits any url into browser then this functions gets called
           It will redirected to appropriate page as per the URL info. If no match
           found in any Route then it opens 404 page
 * @since  1.0.0
 */
import React, { lazy, Suspense } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom';
import LoginPrivateRoute from './LoginPrivateRoute';
import PrivateRoute from './PrivateRoute';

const Loading = () => <div>Loading...</div>;
const NoMatch = lazy(() => import('../components/NoMatch/NoMatch.jsx'));
const PreSearch = lazy(() => import('../components/Search/PreSearch.jsx'));
const PreLogin = lazy(() => import('../components/PreLogin/index.jsx'));

const CommonDashboard = lazy(() =>
  import('../components/CommonDashboard/CommonDashboard.jsx')
);
const PreDashboard = lazy(() =>
  import('../components/Dashboard/PreDashboard.jsx')
);
const PreMyAccount = lazy(() =>
  import('../components/MyAccount/PreMyAccount.jsx')
);
const PreMyProducts = lazy(() =>
  import('../components/MyProducts/PreMyProducts.jsx')
);
const ProviderRequests = lazy(() =>
  import('../components/ProviderRequests/ProviderRequests')
);
const PreMyColleagues = lazy(() =>
  import('../components/MyColleagues/PreMyColleagues.jsx')
);
const OnCallReps = lazy(() => import('../components/OnCallReps'));
const ScheduleRep = lazy(() => import('../components/ScheduleRep'));
const ScheduledAppointments = lazy(() =>
  import('../components/ScheduledAppointments/ScheduledAppointments')
);
const MyCredentials = lazy(() => import('../components/RepCredentials'));
// const WaitingComponent = Component => props => <Component {...props} />;

const RouterExport = () => (
  <Suspense fallback={<Loading />}>
    <Router>
      <Switch>
        <LoginPrivateRoute path="/Signup" component={PreLogin} />
        <LoginPrivateRoute path="/ClaimAccount" component={PreLogin} />
        <LoginPrivateRoute path="/ForgotPassword" component={PreLogin} />
        <LoginPrivateRoute path="/ResetPassword/:token" component={PreLogin} />
        <Route path="/VerifyOTP/:token" component={PreLogin} />
        <LoginPrivateRoute path="/Login" component={PreLogin} />
        <PrivateRoute path="/RepDashboard" component={PreDashboard} />
        <PrivateRoute path="/FullProfile/:profileId" component={PreDashboard} />
        <PrivateRoute path="/FullProfile" component={PreDashboard} />
        <PrivateRoute path="/r/:profileurl" component={PreDashboard} />
        <PrivateRoute path="/RepMyProducts" component={PreMyProducts} />
        <PrivateRoute path="/RepMyAccount" component={PreMyAccount} />
        <PrivateRoute path="/RepMyColleagues" component={PreMyColleagues} />
        <PrivateRoute path="/RepMyCredentials" component={MyCredentials} />
        <PrivateRoute path="/RepMyProducts" component={PreMyProducts} />
        <PrivateRoute path="/ProviderRequests" component={ProviderRequests} />
        <PrivateRoute path="/Dashboard" component={CommonDashboard} />
        <PrivateRoute path="/SearchRep" component={PreSearch} />
        <PrivateRoute path="/OnCallReps" component={OnCallReps} />
        <PrivateRoute path="/ScheduleRep" component={ScheduleRep} />
        <PrivateRoute
          path="/ScheduledAppointments"
          component={ScheduledAppointments}
        />
        <Route path="/appointment/confirmation/:token" component={PreLogin} />
        <Route path="/404" component={NoMatch} />
        <Redirect from="/" to="/Login" />
        <Redirect from="*" to="/404" />
      </Switch>
    </Router>
  </Suspense>
);

export default RouterExport;
