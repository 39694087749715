/**
 * @doc
 * Listen when the DOM is fully loaded and have window event listeners in one place.
 */

import PublishSubscribe from 'publish-subscribe-js';
import PUB_SUB from '../constants/events.constant';
import { msgReceive } from './broadcastMessage';
import { axiosApi } from '../apis/axiosApiCall';

function initPageVisibilityAPI() {
  let hidden;
  let visibilityChange;

  // Opera 12.10 and Firefox 18 and later support
  if (typeof document.hidden !== 'undefined') {
    hidden = 'hidden';
    visibilityChange = 'visibilitychange';
  } else if (typeof document.msHidden !== 'undefined') {
    hidden = 'msHidden';
    visibilityChange = 'msvisibilitychange';
  } else if (typeof document.webkitHidden !== 'undefined') {
    hidden = 'webkitHidden';
    visibilityChange = 'webkitvisibilitychange';
  }

  // Warn if the browser doesn't support addEventListener or the Page Visibility API
  if (
    typeof document.addEventListener === 'undefined' ||
    typeof document.hidden === 'undefined'
  ) {
    alert(
      'This demo requires a browser, such as Google Chrome or Firefox, that supports the Page Visibility API.'
    );
  } else {
    document.addEventListener(
      visibilityChange,
      (event) => {
        if (document[hidden]) {
          PublishSubscribe.publish(PUB_SUB.PAGE_VISIBILITY, { event });
        } else {
          // page is visible you may continue doing what was stopped
          PublishSubscribe.publish(PUB_SUB.PAGE_VISIBILITY, {
            event,
            action: 'continue',
          });
        }
      },
      false
    );
  }
}

const pageInit = () => {
  // Handler when the DOM is fully loaded

  // Window Visibilitychange Listener
  initPageVisibilityAPI();

  document.addEventListener('mousedown', (event) => {
    PublishSubscribe.publish(PUB_SUB.MOUSEDOWN, {
      event,
    });
  });

  document.addEventListener('scroll', (event) => {
    PublishSubscribe.publish(PUB_SUB.MOUSESCROLL, {
      event,
    });
  });

  window.addEventListener('resize', () => {
    PublishSubscribe.publish(PUB_SUB.RESIZE, {
      width: window.innerWidth,
      height: window.innerHeight,
    });
  });

  // To notify other tabs
  window.addEventListener('storage', msgReceive);
  // $(window).on('storage', function(e) { msgReceive(e.originalEvent) });

  // This is used for sending any client error to server via API
  window.onerror = (msg, url, lineNo, columnNo, error) => {
    const string = msg.toLowerCase();
    const substring = 'script error';
    if (string.indexOf(substring) > -1) {
      // alert('Script Error: See Browser Console for Detail');
    } else {
      const payloadObj = {
        Message: msg,
        URL: url,
        Line: lineNo,
        Column: columnNo,
        Error: JSON.stringify(error.stack),
      };
      // Send all the client error to server
      axiosApi('/errorHandle', 'POST', payloadObj, () => {});
    }
    return false;
  };
};

export default function DOMReady() {
  if (
    document.readyState === 'complete' ||
    (document.readyState !== 'loading' && !document.documentElement.doScroll)
  ) {
    pageInit();
  } else {
    document.addEventListener('DOMContentLoaded', pageInit);
  }
}
